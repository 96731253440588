<template>
  <cv-form class="confirmOrder" @submit.prevent="sendMessage">
    <cv-grid>
      <cv-row>
        <cv-column>
          <h1>Inserisci i tuoi dati</h1>
          <span>Compilare tutti i campi per inviare la prenotazione.</span>
          <cv-text-input
            v-model="customer"
            placeholder="Nome e Cognome"
            @input="checkCustomerName"
            :value="customer"
          >
            <template v-if="customerNameErrorSlot" slot="invalid-message"
              >Inserire il nome e il cognome</template
            >
          </cv-text-input>
        </cv-column>
      </cv-row>
      <cv-row>
        <cv-column>
          <cv-text-input
            v-model="customerPhone"
            @input="checkCustomerPhone"
            placeholder="Numero di telefono"
          >
            <template v-if="customerPhoneErrorSlot" slot="invalid-message"
              >Inserire il numero di telefono</template
            >
          </cv-text-input>
        </cv-column>
      </cv-row>
      <cv-row>
        <cv-column>
          <cv-date-picker
            kind="single"
            pattern="\d{1,2}/\d{1,2}/\d{4}"
            placeholder="gg/mm/yyyy"
            dateLabel="Data di ritiro"
            v-model="orderDate"
            @input="checkOrderDate"
            :cal-options="calOptions"
            name="datePickr"
          >
            <template v-if="customerDateErrorSlot" slot="invalid-message"
              >Inserire la data per il ritiro</template
            >
          </cv-date-picker>
        </cv-column>
        <cv-column>
          <cv-date-picker
            kind="single"
            pattern="\d{1,2}:\d{1,2}"
            placeholder="hh:mm"
            dateLabel="Ora di ritiro"
            v-model="orderTime"
            @input="checkOrderTime"
            :cal-options="timeOptions"
            name="timePickr"
          >
            <template v-if="customerTimeErrorSlot" slot="invalid-message"
              >Inserire l'ora per il ritiro</template
            >
          </cv-date-picker>
        </cv-column>
      </cv-row>
      <cv-row>
        <cv-column>
          <p>
            La tua prenotazione verrà inviata tramite Whatsapp e sarai
            contattato da un nostro operatore per confermare il ritiro.
          </p>
        </cv-column>
      </cv-row>
    </cv-grid>
    <cv-button-set stacked="stacked">
      <router-link to="/checkout" custom v-slot="{ navigate }">
        <cv-button @click="navigate" size="lg" kind="secondary"
          >Indietro</cv-button
        >
      </router-link>
      <cv-button :icon="SendAltFilled32" size="lg" @click="sendMessage"
        >Invia prenotazione
      </cv-button>
    </cv-button-set>
  </cv-form>
</template>

<script>
import SendAltFilled32 from '@carbon/icons-vue/es/send--alt--filled/32';

export default {
  name: 'ConfirmOrder',
  data() {
    return {
      calOptions: { dateFormat: 'd/m/Y' },
      timeOptions: { enableTime: true, noCalendar: true, dateFormat: 'H:i' },
      phoneNumber: '0804793447',
      customerNameErrorSlot: false,
      customerDateErrorSlot: false,
      customerPhoneErrorSlot: false,
      customerTimeErrorSlot: false,
      customer: '',
      customerPhone: '',
      orderTime: '',
      orderDate: '',
      SendAltFilled32
    };
  },
  methods: {
    checkForm() {
      if (this.customer == '') {
        this.customerNameErrorSlot = true;
        return false;
      } else this.customerNameErrorSlot = false;
      if (this.customerPhone == '') {
        this.customerPhoneErrorSlot = true;
        return false;
      } else this.customerPhoneErrorSlot = false;
      if (this.orderDate == '') {
        this.customerDateErrorSlot = true;
        return false;
      } else this.customerDateErrorSlot = false;
      if (this.orderTime == '') {
        this.customerTimeErrorSlot = true;
        return false;
      } else this.customerTimeErrorSlot = false;
      return true;
    },
    checkCustomerName(e) {
      if (this.customerNameErrorSlot && e != '')
        this.customerNameErrorSlot = false;
    },
    checkCustomerPhone(e) {
      if (this.customerPhoneErrorSlot && e != '')
        this.customerPhoneErrorSlot = false;
    },
    checkOrderDate(e) {
      if (this.customerDateErrorSlot && e != '')
        this.customerDateErrorSlot = false;
    },
    checkOrderTime(e) {
      if (this.customerTimeErrorSlot && e != '')
        this.customerTimeErrorSlot = false;
    },
    sendMessage(e) {
      e.preventDefault();
      if (this.checkForm()) {
        let message = [
          'Nuova prenotazione',
          `Cliente: ${this.customer}`,
          `Telefono: ${this.customerPhone}`,
          'Data consegna richiesta:',
          `${this.orderDate} alle ${this.orderTime}`,
          'Prodotti:',
          Object.values(this.$store.state.cart)
            .map(item => {
              return [item.qty, 'x *', item.descr,'* - ',item.brand.toUpperCase(),' - ',item.size].join('');
            })
            .join(`%0a`)
        ].join(`%0a`);
        console.log(message);
        window.open(
          `https://api.whatsapp.com/send?phone=39${this.phoneNumber}&text=${message}`
        );
      } 
    }
  }
};
</script>

<style lang="scss">
@use 'carbon-components/scss/globals/scss/spacing' as s;

.confirmOrder {
  overflow: hidden;

  .cv-grid .cv-row {
    padding: s.$spacing-03 0;
  }
  .cv-button-set {
    position: absolute;
    bottom: 0;
    width: 100%;

    .cv-button {
      max-width: 100%;

      &:last-child {
        margin-top: s.$spacing-01;
      }
    }
  }
  p {
    padding: s.$spacing-05 0 s.$spacing-13 0;
  }
}
</style>
